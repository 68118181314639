import React from "react";
import "./Testimonials.css";
import testimonialimg from "../../utils/Images/Vector (4).png";
import ArrowRight from '../../utils/Icons/Vector (25).svg';
import ArrowLeft from '../../utils/Icons/Vector (26).svg';
import Carousel from "react-material-ui-carousel";
import { Paper, Typography } from "@mui/material";

function Testimonials() {
  const testimonials = [
    {
      content: "I have used Raigad Consulting for some e-Commerce and CMS projects and found them technically competent. Team has been extremely focused to get it executed well in time.",
      name: "Linda S",
      // position: "CEO Midway"
    },
    {
      content: "We were extremely pleased with Raigad. Not only do they surprise you with extraordinary designs but also the lightning speed of turnaround time and deliveries is unbelievable.",
      name: "Kelly Johnson",
      // position: "Marketing Head"
    },
    {
      content: "On behalf of management, I would like to thank you and your team who have done good work on our project. I appreciate the hard work and dedication towards the project, which helped me in achieving my business goals, Many Thanks. Keep it up!",
      name: "Mark White",
      // position: "Project Manager"
    }
  ];

  return (
    <div className="testimonial-main">
      <div className="testimonial-div">
        <p className="about-1">Testimonials</p>
        <div className="sub-div">
          <div className="inner-test">
            <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
              <p className="service-para">What people are saying</p>
              <Carousel
                animation="slide"
                // timeout={300}
                navButtonsAlwaysInvisible={true}
                NextIcon={<img src={ArrowRight} alt="Next" className="arrow-img" />}
                PrevIcon={<img src={ArrowLeft} alt="Previous" className="arrow-img" />}
                className="testimonial-carousel"
              >
                {testimonials.map((testimonial, index) => (
                  <Paper key={index} className="testimonial-content" 
                  sx={{
                    minHeight: "270px",
                    height: "auto",
                    boxShadow:"none"
                  }}>
                    <div className="image-test">
                      <img src={ testimonialimg} alt="Testimonial" className="image-test" />
                      <img src={testimonialimg} alt="Testimonial" className="image-test" />
                    </div>
                    <div className="content-main">
                      <p variant="body1" className="test-content">
                        {testimonial.content}
                      </p>
                      <div>
                        <p className="person-name">{testimonial.name}</p>
                        <p  className="position">{testimonial.position}</p>
                      </div>
                    </div>
                  </Paper>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
