import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Content1 from "../../utils/Images/Content.png";
import { useNavigate, useLocation } from "react-router-dom";

function Content() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };
  return (
    <>
      <Navbar />
      <div className="first-section">
        <img
          src={Content1}
          alt="Web Development"
          className="web-img"
          width="100%"
        />
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
            {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Content Creation</span>
        </p>
        <p className="heading-web">Content Creation</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                SEO-Optimized
                <br /> Content
              </p>
              <p className="card-sub-content">
                Drive organic traffic and improve search engine ranking.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Engaging Blog Posts & <br />
                Articles
              </p>
              <p className="card-sub-content">
                Inform, educate, and entertain your target audience.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Website Content & <br />
                Copywriting
              </p>
              <p className="card-sub-content">
                Craft compelling website copy that converts visitors into
                customers.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Social Media Content <br /> Calendar & Management
              </p>
              <p className="card-sub-content">
                Create a consistent and engaging social media presence.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              In today's digital landscape, content is king. High-quality,
              informative, and engaging content is essential for attracting and
              retaining your target audience.
              <span className="bold-para"> Raigad Consulting's </span>team of
              skilled writers creates compelling content that is both
              informative and SEO-optimized. 
            </li>
            <li className="list-items">
              We develop content strategies that align with your business goals
              and target audience. Our writers can create a variety of content
              formats, including blog posts, articles, website copy, social
              media content, and more
            </li>
            <li className="list-items bold-para">
              Increase brand awareness and customer engagement
              <ul className="inner-ul">
                <li className="list-items">
                  Improve your search engine ranking and drive organic traffic
                  to your website
                </li>
                <li className="list-items">
                  {" "}
                  Generate leads and convert website visitors into customers
                </li>
                <li className="list-items">
                  Build brand awareness and establish yourself as a thought
                  leader in your industry
                </li>
                <li className="list-items">
                  Increase customer engagement and loyalty
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Content;
