import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import SMO1 from "../../utils/Images/SMO.png";
import { useNavigate, useLocation } from "react-router-dom";

function SMO() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={SMO1} alt="Web Development" className="web-img" width="100%"/>
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Social Media Optimization</span>
        </p>
        <p className="heading-web">Social Media Optimization (SMO)</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Strategic Social
                <br /> Media Planning
              </p>
              <p className="card-sub-content">
                Develop a social media strategy that aligns with your business
                goals.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Content Creation &<br /> Management
              </p>
              <p className="card-sub-content">
                Create engaging content for your social media channels.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Community Management
                <br /> & Engagement
              </p>
              <p className="card-sub-content">
                Build relationships with your audience on social media.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Social Media Advertising &<br /> Paid Promotions
              </p>
              <p className="card-sub-content">
                Reach a wider audience with targeted social media advertising.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              Social media is a powerful tool for connecting with your target
              audience, promoting your brand, and driving traffic to your
              website. However, navigating the ever-changing social media
              landscape can be challenging. Raigad Consulting's social media
              optimization (SMO) services can help you create and execute a
              successful social media strategy.
            </li>
            <li className="list-items">
              Our team of social media experts will work with you to develop a
              content calendar, manage your social media channels, and engage
              with your audience. We can also help you create targeted social
              media advertising campaigns to reach a wider audience and achieve
              your business goals.
            </li>
            <li className="list-items">
              We work closely with you throughout the entire development
              process, from initial concept to final launch, to ensure that your
              app meets your specific needs and objectives.
            </li>
            <li className="list-items bold-para">
              Partner with Raigad Consulting to leverage the power of social
              media and take your brand to the next level.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SMO;
