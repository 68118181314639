// import React from "react";
// import "./Team.css";
// import teamimage from "../../utils/Images/two-smiling-female-coworkers-using-tablet-computer-cafe 1 (1).png";
// import backgroundimg from "../../utils/Icons/Rectangle 46.svg";
// import { Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// function Team() {
  // const nav = useNavigate();
  // const handleOpeningClick = () => {
  //   nav("/careers");
  // };

//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           height: "100%",
//           position: "relative",
//           paddingTop: "90px",
//         }}
//       >
//         <div
//           style={{
//             position: "absolute",
//             top: "0",

//             display: "flex",

//             gap: "40px",
//             width: "100%",
//             alignItems: "center",
//           }}
//         >
//           <div style={{ height: "100%", width: "100%" }}>
//             <img src={teamimage} alt="teamimage" />
//           </div>
//           <div className="main-about">
//   <div className="global-about">
//     <div>
//       <p className="team-1 ">JOIN OUR TEAM</p>
//     </div>
//     <p className="raigad" style={{ color: "white" }}>
//       At Raigad Consultancy, we are always on the lookout for talented
//       and driven individuals to join our team. If you are passionate
//       about technology, innovation, and making a positive impact on
//       businesses, we encourage you to explore our career
//       opportunities.
//     </p>
//     <div className="button-div-1">
//       <Button
//         sx={{
//           borderRadius: "10px",
//           padding: "10px 30px",
//           background: "#FF9515",
//           "&:hover": {
//             background: "#FF9515",
//           },
//         }}
//         className="button-about"
//         onClick={handleOpeningClick}
//       >
//         <p className="read-more">View Openings +</p>
//       </Button>
//     </div>
//   </div>
// </div>
//         </div>
//         <img
//           src={backgroundimg}
//           alt="background"
//           style={{
//             width: "100%",
//             height: "100%",
//             objectFit: "cover",
//             paddingTop: "30px",
//             paddingBottom: "10px",
//           }}
//         />
//       </div>
//     </>
//   );
// }

// export default Team;
import React from "react";
import teamimage from "../../utils/Images/two-smiling-female-coworkers-using-tablet-computer-cafe 1 (1).png";
import backgroundimg from "../../utils/Icons/Rectangle 46.svg";
import "./Team.css";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

function Team() {
  const nav = useNavigate();
  const handleOpeningClick = () => {
    nav("/careers");
  };
  return (
    <>
      <div className="team-main">
        <div className="sub-div-team">
          <div className="image-container">
          <img src={teamimage} alt="team-image"/></div>
          <div className="main-about" style={{marginTop:"10%"}}>
            <div className="global-about">
              <div>
                <p className="team-1">JOIN OUR TEAM</p>
              </div>
              <p className="raigad" style={{ color: "white" }}>
                At Raigad Consulting, we are always on the lookout for talented
                and driven individuals to join our team. If you are passionate
                about technology, innovation, and making a positive impact on
                businesses, we encourage you to explore our career
                opportunities.
              </p>
              <div className="button-div-1">
                <Button
                  sx={{
                    borderRadius: "10px",
                    padding: "10px 30px",
                    background: "#FF9515",
                    "&:hover": {
                      background: "#FF9515",
                    },
                  }}
                  className="button-about"
                  onClick={handleOpeningClick}
                >
                  <p className="read-more">View Openings +</p>
                </Button>
              </div>
            </div>
          </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}

export default Team;
