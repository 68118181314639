import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import SEO1 from "../../utils/Images/SEO.png";
import { useNavigate, useLocation } from "react-router-dom";

function SEO() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={SEO1} alt="Web Development" className="web-img" width="100%"/>
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Search Engine Optimization (SEO)</span>
        </p>
        <p className="heading-web">Search Engine Optimization (SEO)</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Keyword Research & <br />
                Targeting
              </p>
              <p className="card-sub-content">
                Identify the keywords your target audience is searching for.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                On-Page
                <br />
                Optimization
              </p>
              <p className="card-sub-content">
                Optimize your website content and structure for search engines.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Off-Page <br />
                Optimization
              </p>
              <p className="card-sub-content">
                Build backlinks and increase your website's authority.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Technical SEO Audit &<br /> Optimization
              </p>
              <p className="card-sub-content">
                Ensure your website is technically sound and search engine
                friendly.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              In today's digital world, search engine optimization (SEO) is
              essential for increasing website visibility and driving organic
              traffic.<span className="bold-para"> Raigad Consulting's</span>{" "}
              SEO experts have the knowledge and expertise to help you improve
              your website's ranking in search engine results pages (SERPs).
            </li>
            <li className="list-items">
              We offer a comprehensive range of 
              <ul className="inner-ul">
                <li className="list-items">pen_spark</li>
                <li className="list-items">tuneshare </li>
                <li className="list-items">more_vert</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SEO;
