import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Mobile from "../../utils/Images/Mobile.png";
import { useNavigate, useLocation } from "react-router-dom";

function Mobiledeveloment() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={Mobile} alt="Web Development" className="web-img" width="100%"/>
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd"> Mobile App Development</span>
        </p>
        <p className="heading-web"> Mobile App Development</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Feature-Rich iOS &<br /> Android Apps
              </p>
              <p className="card-sub-content">
                Build custom apps to meet your unique business needs.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Enhanced User
                <br /> Engagement
              </p>
              <p className="card-sub-content">
                Deliver a seamless and intuitive user experience.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Reach a Wider
                <br />
                Audience
              </p>
              <p className="card-sub-content">
                Connect with your customers wherever they are.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Data-Driven
                <br /> Insights
              </p>
              <p className="card-sub-content">
                Gain valuable user data to optimize your app and marketing
                strategies.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              In today's mobile-first world, a well-designed mobile app is
              essential for reaching your target audience and staying ahead of
              the competition.
              <span className="bold-para"> Raigad Consulting</span> provides
              comprehensive mobile app development services to help you create
              engaging and impactful mobile applications. Our team of skilled
              developers has extensive experience in building feature-rich iOS
              and Android apps for a wide range of industries.
            </li>
            <li className="list-items">
              Our team of skilled developers has extensive experience in
              building feature-rich iOS and Android apps for a wide range of
              industries.
            </li>
            <li className="list-items">
              We work closely with you throughout the entire development
              process, from initial concept to final launch, to ensure that your
              app meets your specific needs and objectives.
            </li>
            <li className="list-items">
              Raigad Consulting's mobile app development services can help you:
            </li>
            <li className="list-items bold-para">
              Increase brand awareness and customer engagement
              <ul className="inner-ul">
                <li className="list-items">
                  Increase brand awareness and customer engagement
                </li>
                <li className="list-items">Drive sales and revenue</li>
                <li className="list-items">
                  Improve customer service and loyalty
                </li>
                <li className="list-items">
                  Gain valuable data and insights about your target audience
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Mobiledeveloment;
