import React, { useEffect, useState } from "react";
import emailjs from '@emailjs/browser';
import { Background } from "../Background";
import Navbar from "../Navbar/Navbar";
import "./Contact.css";
import { Button, TextField, useMediaQuery, useTheme, Grid, FormControl, Typography } from "@mui/material";
import contactimg from "../../utils/Images/Image BG.png";
import mail from "../../utils/Icons/Vector (21).svg";
import phone from "../../utils/Icons/Vector (22).svg";
import location from "../../utils/Icons/Vector (23).svg";
import mapimage from "../../utils/Images/Image BG (2).png";
import Footer from "../Footer/Footer";
import {message} from 'antd'


function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      message: formData.message
    };

    emailjs.send(
      'contactus-service',
      'template_agmdf3h',
      templateParams,
      'Y8lQ7esJpf4lQJ4qh'
    ).then((result) => {
      console.log(result.text);
      message.success({
        content: "Message sent successfully", 
        className: "custom-class",
        style: {
          marginTop: "20vh",
        },
      })
    }, (error) => {
      console.log(error.text);
    });
  };

  return (
    <div className="main">
      <Background color="#E3E3E3">
        <Navbar />
        <div className="main-div-contact" style={{ background: "#E3E3E3" }}>
          <div className="flex-display-11">
            <p className="about-1">Contact</p>
            <div className="sub-div">
              <p className="service-para">More about <br />you</p>
            </div>
            <form className="form-container" onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">First Name</p>
                    </label>
                    <TextField
                      size="small"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">Last Name</p>
                    </label>
                    <TextField
                      size="small"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">Email</p>
                    </label>
                    <TextField
                      size="small"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">Phone Number</p>
                    </label>
                    <TextField
                      size="small"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">Message</p>
                    </label>
                    <TextField
                      name="message"
                      multiline={true}
                      rows="2"
                      value={formData.message}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          // borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required variant="standard">
                    <Button
                      type="submit"
                      sx={{
                        background: "#FF9515",
                        "&:hover": {
                          background: "#FF9515",
                        },
                      }}
                    >
                      <p className="button-text1">Send a Message +</p>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
            <div className="last-sec">
              <div className="contact-info">
                <p className="contact-section">Contact Us</p>
                <div className="contact-div">
                  <div className="contact-info-1">
                    <img src={location} alt="location" />
                    <p className="content-style"> London, UK</p>
                  </div>
                  <div className="contact-info-1">
                    <img src={phone} alt="phone number" />
                    <p className="content-style">+44 7714462152</p>
                  </div>
                  <div className="contact-info-1">
                    <img src={mail} alt="mail" />
                    <p className="content-style">hello@raigad-consulting.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="image-container">
            <img src={contactimg} alt="About" />
          </div>
        </div>
      </Background>
      <div className="map-section">
        <img src={mapimage} alt="Map" width="100%" />
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
