import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Design1 from "../../utils/Images/Design.png";
import { useNavigate, useLocation } from "react-router-dom";

function Design() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={Design1} alt="Web Development" className="web-img" width="100%"/>
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd"> Design & Creatives</span>
        </p>
        <p className="heading-web">Design & Creatives</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Logo Design &<br /> Branding
              </p>
              <p className="card-sub-content">
                Create a lasting impression with a memorable brand identity.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Social Media Graphics &<br /> Content Creation
              </p>
              <p className="card-sub-content">
                Captivate your audience with visually engaging content.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Website Design & User
                <br /> Interface (UI)
              </p>
              <p className="card-sub-content">
                Design a website that is both aesthetically pleasing and
                user-friendly.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Marketing Materials &<br />
                Collateral
              </p>
              <p className="card-sub-content">
                Design impactful marketing materials to support your campaigns.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              In today's competitive marketplace, a strong brand identity is
              essential for standing out from the crowd.{" "}
              <span className="bold-para"> Raigad Consulting's</span>{" "}
              team of talented designers creates captivating visuals that
              elevate your brand identity and leave a lasting impression.  
            </li>
            <li className="list-items">
              We offer a wide range of design services, including logo design,
              branding, social media graphics, website design, user interface
              (UI) design, and marketing materials.
            </li>
            <li className="list-items">
              Our designers are passionate about creating visually stunning and
              strategically aligned creative assets that resonate with your
              target audience and support your overall marketing goals. 
            </li>
            <li className="list-items bold-para">
              Partner with Raigad Consulting to develop a cohesive design
              strategy that will help you achieve your business objectives.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Design;
