import "./App.css";
import Navbar from "../src/Components/Home/Navbar";
import Index from "./Components/Services/Index";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Web from "./Components/Services/Web";
import Mobiledeveloment from "./Components/Services/Mobiledeveloment";
import Design from "./Components/Services/Design";
import SMO from "./Components/Services/SMO";
import Content from "./Components/Services/Content";
import Sales from "./Components/Services/Sales";
import SEO from "./Components/Services/SEO";
import RPA from "./Components/Services/RPA";
import AI from "./Components/Services/AI";
import BC from "./Components/Services/BC";
import NavBar from "../src/Components/Home/Navbar";
import Careers from "./Components/Careers/Careers";
import Aboutus from './Components/About/Aboutus'
import Contact from "./Components/Contact/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navbar />} />
          <Route path="/home" element={<Navbar />} />
          <Route path="/services" element={<Index />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/aboutus" element={<Aboutus />} />
          
          
         
          <Route path="/services/web-development" element={<Web />} />
          <Route path="/services/mobile-development" element={<Mobiledeveloment />} />
          <Route path="/services/design-and-creatives" element={<Design />} />
          <Route path="/services/content-creation" element={<Content />} />
          <Route path="/services/social-media-optimization" element={<SMO />} />
          <Route path="/services/sales-and-marketing-experts" element={<Sales />} />
          <Route path="/services/search-engine-optimization" element={<SEO />} />
          <Route path="/services/robotic-process-automation" element={<RPA />} />
          <Route path="/services/artificial-intelligence" element={<AI />} />
          <Route path="/services/business-consulting" element={<BC />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
