import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Robotic from "../../utils/Images/RPA.png";
import { useNavigate, useLocation } from "react-router-dom";

function RPA() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={Robotic} alt="Web Development" className="web-img" width="100%"/>
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Robotic Process Automation (RPA)</span>
        </p>
        <p className="heading-web">Robotic Process Automation (RPA)</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Identify Repetitive
                <br /> Tasks
              </p>
              <p className="card-sub-content">
                Automate manual, rule-based tasks for increased efficiency.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Enhanced Accuracy & <br />
                Reduced Errors
              </p>
              <p className="card-sub-content">
                Eliminate human error and ensure data accuracy.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Improved Employee <br />
                Satisfaction
              </p>
              <p className="card-sub-content">
                Free up your workforce for higher-value activities.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Scalability & Cost
                <br /> Reduction
              </p>
              <p className="card-sub-content">
                Automate tasks and scale your operations efficiently.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              In today's fast-paced business environment, streamlining
              operations and improving efficiency is crucial for success.{" "}
              <span className="bold-para">Raigad Consulting's</span> Robotic
              Process Automation (RPA) solutions can help you automate
              repetitive and manual tasks, freeing up your workforce to focus on
              higher-value activities.
            </li>
            <li className="list-items">
              RPA automates tasks using software robots that mimic human
              actions. This can significantly improve efficiency, reduce errors,
              and free up your employees to focus on more strategic initiatives.
            </li>

            <li className="list-items bold-para">
              Raigad Consulting's RPA services can help you
              <ul className="inner-ul">
                <li className="list-items">
                  Identify tasks that are suitable for automation
                </li>
                <li className="list-items">
                  {" "}
                  Develop and implement RPA solutions
                </li>
                <li className="list-items">
                  Integrate RPA with your existing systems
                </li>
                <li className="list-items">
                  Measure the ROI (return on investment) of your RPA initiatives
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RPA;
