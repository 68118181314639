import React, { useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import AI1 from "../../utils/Images/AI.png";
import { useNavigate, useLocation } from "react-router-dom";

function AI() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={AI1} alt="Artificial Intelligence" className="web-img" width="100%" />
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
            {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Artificial Intelligence (AI)</span>
        </p>
        <p className="heading-web">Artificial Intelligence (AI)</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Predictive
                <br />
                Analytics
              </p>
              <p className="card-sub-content">
                Gain valuable insights from your data to predict future trends.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Personalized Customer
                <br /> Experiences
              </p>
              <p className="card-sub-content">
                Deliver personalized experiences to your customers.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Improved Marketing &<br /> Sales Strategies
              </p>
              <p className="card-sub-content">
                Leverage AI to optimize your marketing and sales campaigns.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Enhanced Decision-
                <br />
                Making
              </p>
              <p className="card-sub-content">
                Make data-driven decisions to achieve your business goals.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              Artificial intelligence (AI) is rapidly transforming the business
              landscape. Raigad Consulting can help you leverage the power of AI
              to gain valuable insights from your data, personalize customer
              experiences, and improve your overall business operations.
            </li>
            <li className="list-items">
              Our team of AI experts can help you develop and implement AI
              solutions that are tailored to your specific business needs. We
              can help you leverage AI for predictive analytics, personalized
              marketing, improved sales forecasting, and more.
            </li>
            <li className="list-items bold-para">
              Partner with Raigad Consulting to unlock the power of AI and gain
              a competitive edge in the marketplace.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AI;
