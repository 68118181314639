import React from "react";
import "./services.css";
import { Card, Grid } from "@mui/material";
import Web from "../../utils/Icons/Group.svg";
import Content from '../../utils/Icons/Contentdev.svg';
import Mobile from '../../utils/Icons/mobiledev.svg';
import Design from '../../utils/Icons/Designdev.svg';
import SMO from '../../utils/Icons/Smodev.svg';
import sales from '../../utils/Icons/salesdev.svg';
import Business from '../../utils/Icons/businessdev.svg';
import AI from '../../utils/Icons/aidev.svg';
import Search from '../../utils/Icons/searchdev.svg';
import Robotic from '../../utils/Icons/roboticdev.svg';
import { useNavigate } from "react-router-dom";

function Services() {
  const navigate = useNavigate();

  const serviceRoutes = {
    "Web Development": "/services/web-development",
    "Mobile App Development": "/services/mobile-development",
    "Design & Creatives": "/services/design-and-creatives",
    "Content Creation": "/services/content-creation",
    "Social Media Optimization (SMO)": "/services/social-media-optimization",
    "Sales & Marketing Experts": "/services/sales-and-marketing-experts",
    "Search Engine Optimization (SEO)": "/services/search-engine-optimization",
    "Robotic Process Automation (RPA)": "/services/robotic-process-automation",
    "Artificial Intelligence (AI)": "/services/artificial-intelligence",
    "Business Consulting": "/services/business-consulting"
  };

  const handleClick = (title) => {
    const route = serviceRoutes[title];
    if (route) {
      navigate(route, { state: { title, home: 'Home' } });
    }
  };
  

  const services = [
    { title: "Web<br/> Development", image: Web },
    { title: "Mobile App<br/> Development", image: Mobile },
    { title: "Design & <br/>Creatives", image: Design },
    { title: "Content<br/> Creation", image: Content },
    { title: "Social Media Optimization<br/> (SMO)", image: SMO },
    { title: "Sales & Marketing <br/> Experts", image: sales },
    { title: "Search Engine Optimization<br/> (SEO)", image: Search },
    { title: "Robotic Process Automation<br/> (RPA)", image: Robotic },
    { title: "Artificial Intelligence<br/> (AI)", image: AI },
    { title: "Business <br/>Consulting", image: Business },
  ];

  return (
    <div className="services">
      <div className="heading">
        <p className="service-heading">Services We Offer</p>
      </div>
      <Grid container spacing={3} className="card-container" sx={{marginBottom:"5%"}}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
            <Card className="service-card" sx={{ borderRadius: "10px" }} onClick={() => handleClick(service.title)}>
              <div className="card1">
                <img src={service.image} alt={service.title} width="50px" height="50px" />
                <p className="font-web" dangerouslySetInnerHTML={{ __html: service.title }} />
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Services;
