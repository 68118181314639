import React from "react";
import Facebook from "../../utils/Icons/ic_outline-facebook.svg";
import YouTube from "../../utils/Icons/mdi_youtube.svg";
import Twitter from "../../utils/Icons/mdi_twitter.svg";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const nav = useNavigate()
  const handleHomeClick = () => {
    nav('/')
  };
  const handleAboutClick=()=>{
    nav('/aboutus')
  }
  const handleServiceClick=()=>{
    nav('/services')
  }
  const handleContactClick=()=>{
    nav('/contact')
  }
  const handleCareersClick=()=>{
    nav('/careers')
  }
  return (
    <>
      <div style={{ background: "#363636" }}>
        <div className="main-footer">
          <div className="footer-section">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
             <div className="first-sec">
                <h6 className="quick">Quick Link</h6>
                <div className="first-sec-sub">
                  <div style={{ display: "flex", gap:"20px" }}>
                    <div style={{display:"flex",flexDirection:"column",gap:"20px",textAlign:"left",cursor:"pointer"}}>
                      <p className="links" onClick={handleHomeClick}>Home</p>
                      <p className="links" onClick={handleAboutClick}>About</p>
                      <p className="links" onClick={handleCareersClick}>Careers</p>
                    </div>
                    <div style={{display:"flex",flexDirection:"column",gap:"20px",textAlign:"left",cursor:"pointer"}}>
                      {" "}
                      <p className="links">&nbsp;</p>
                      <p className="links" onClick={handleServiceClick}>Services</p>
                      <p className="links" onClick={handleContactClick}>Contact</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <h6 className="follow">Follow us</h6>
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "20px" }}
              >
                <img src={Facebook} alt="Facebook" className="image"/>
                <img src={YouTube} alt="YouTube" className="image"/>
                <img src={Twitter} alt="Twitter" className="image"/> */}
              {/* </div> */}
            </div>
            <div className="right-footer">
              {/* <div className="first-sec">
                <h6 className="quick">Quick Link</h6>
                <div className="first-sec-sub">
                  <div style={{ display: "flex", gap:"20px" }}>
                    <div style={{display:"flex",flexDirection:"column",gap:"20px",textAlign:"left"}}>
                      <p className="links">Home</p>
                      <p className="links">About</p>
                      <p className="links">Careers</p>
                    </div>
                    <div style={{display:"flex",flexDirection:"column",gap:"20px",textAlign:"left"}}>
                      {" "}
                      <p className="links">&nbsp;</p>
                      <p className="links">Services</p>
                      <p className="links">Contact</p>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="first-sec">
              <h6 className="quick">Contact us :</h6>
              <div style={{display:"flex",flexDirection:"column",gap:"20px",textAlign:"left"}}>
              <p className="links">London, UK</p>
              <p className="links">+44 7714462152</p>
              <p className="links">hello@raigad-consulting.com</p>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <p className="copyright-text">
            Copyright 2024 © All Rights Reserved by Raigad Consulting
          </p>
        </div>
      </div>
    </>
  );
};
export default Footer;
