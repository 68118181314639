import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import plus from "../../utils/Icons/Vector (24).svg";
import "./CvForm.css";
import { useNavigate } from "react-router-dom";


const CVForm = ({ experience, responsibilities, setExpandedPosition, title, subFeatures }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  const handleFiles = (event) => {
    setFiles(event.target.files);
    setIsUploading(true);
    setUploadComplete(false);
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);
      if (progress >= 100) {
        clearInterval(interval);
        setIsUploading(false);
        setUploadComplete(true);
      }
    }, 200);
  };

  const handleCancelClick = () => {
    setExpandedPosition(null);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSfuegGXuNWcZrR5sJ1C9TBeBG_P6HbSVwIXJ9W59aVjT7mR-Q/viewform?usp=pp_url&entry.1306518322=${encodeURIComponent(title)}`;
    window.open(formUrl, "_blank");
  };

  return (
    <form className="cv-style" onSubmit={sendEmail} encType="multipart/form-data">
      <p className="send-cv">Send your CV</p>
      <div className="send-style">
        <p className="send-cv-content">
        Step into a New Era of Career Growth and Impact the Future of Technology!
        </p>
        <div className="content-new">
          <div className="content-cv">
          <p className="experience">
              Job Role: <span className="experience-sub">{title}</span>
            </p>
            <p className="experience">
              Experience Required: <span className="experience-sub">{experience}</span>
            </p>
            <p className="experience">
              Job Responsibilities: <span className="experience-sub">{responsibilities}</span>
            </p>
            {subFeatures && subFeatures.length > 0 && (
              <ul className="sub-features-list">
                {subFeatures.map((feature, index) => (
                  <li key={index} className="experience-sub">
                    {feature}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div>
          {/* Form and other content */}
          <div className="form-container">
            <Grid container spacing={4}>
              {/* Form fields and buttons */}
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                sx={{ paddingTop: "32px", paddingLeft: "32px" }}
              >
                <Grid item xs={isSmallScreen ? 6 : 3}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      background: "white",
                      "&:hover": {
                        background: "white",
                        boxShadow: "none",
                        border: "1px solid #FF9515",
                      },
                      "&:active": {
                        background: "#FF9515",
                        boxShadow: "none",
                        border: "1px solid #FF9515",
                      },
                      borderRadius: "10px",
                      boxShadow: "none",
                      border: "1px solid #FF9515",
                    }}
                    onClick={handleCancelClick}
                  >
                    <p className="button-text12" style={{ color: "#FF9515" }}>
                      Cancel
                    </p>
                  </Button>
                </Grid>
                <Grid item xs={isSmallScreen ? 6 : 3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      background: "#FF9515",
                      "&:hover": { background: "#FF9515", boxShadow: "none" },
                      "&:active": { background: "#FF9515", boxShadow: "none" },
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                  >
                    <p className="button-text12">Apply</p>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CVForm;
