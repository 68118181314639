import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import careers from "../../utils/Images/Teambg.png";
import "./Careers.css";
import Footer from "../Footer/Footer";
import CVForm from "./CvForm";

function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expandedPosition, setExpandedPosition] = useState(null);

  const cvFormRef = useRef(null);

  const handleCareerClick = (index) => {
    setExpandedPosition(expandedPosition === index ? null : index);
    if (expandedPosition !== index) {
      setTimeout(() => {
        cvFormRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const positions = [
    {
      title: "BI SENIOR SOLUTION ANALYST",
      experience: "1-3 Years",
      responsibilities:
        "We need strong PL/SQL developers with experience in working on BI. This is not SAP BI.",
      subFeatures: [
        "Duration - 1 Year",
        "Location - Budapest, Hungary EU CITIZEN",
      ],
    },
    {
      title: "SAP BW HANA ARCHITECT",
      experience: "2-5 Years",
      responsibilities:
        "BI, SAP BW, Business Intelligence, Oracle SQL, Data Modeling...",
      subFeatures: [
        "Bachelor's degree in Computer Science, Information Systems or related field, or equivalent experience",
        "Experience working in a fast-paced and challenging environment, with multiple complex projects going on at one time",
        "Experience troubleshooting and resolving complex issues",
        "Experience performing design and development reviews",
        "Demonstrated ability to define architectural concepts and standards",
        "Proficiency in data modeling concepts",
      ],
    },
    {
      title: "SAP BO WEBI DESIGN STUDIO CONSULTANT",
      experience: "1-4 Years",
      responsibilities:
        "WebI, Design Studio, SAP BO, SAP business objects, Oracle BO...",
      subFeatures: [
        "Extensively worked with variables, filters, conditions, breaks, sorting, drill down, slice and dice, Master/Detail, Cross Tab, Charts and alerts for creating Business Objects WEBI reports.",
        "Applied security features of Business Objects like report level & object level security in the universe so as to make the sensitive data secure.",
        "Created WEBI Reports using one or multiples data providers, Merged Dimensions, Union queries as per the report requirements.",
        "System Analysis experience - work with the business on the translation of the Business requirements to data visualization requirements including possible data onboarding.",
      ],
    },
    {
      title: "WEB DEVELOPER",
      experience: "1-3 Years",
      responsibilities:
        "We are looking for an outstanding Web Developer to be responsible for proven working experience in web programming.",
      subFeatures: [
        "Experience in planning and delivering software platforms used across multiple products and organizational units.",
        "Deep expertise and hands on experience with Web Applications and programming languages such as HTML, CSS, JavaScript, JQuery and API's.",
        "Deep functional knowledge or hands on design experience with Web Services (REST, SOAP, etc ..) is needed to be successful in this position.",
        "Strong grasp of security principles and how they apply to E-Commerce applications.",
        "Aggressive problem diagnosis and creative problem solving skills",
      ],
    },
    {
      title: "IPHONE DEVELOPER",
      experience: "2-5 Years",
      responsibilities:
        "Design and build advanced applications for the iOS platform. Continuously discover, evaluate, and implement new technologies to maximize development efficiency.",
      subFeatures: [
        "BS/MS degree in Computer Science, Engineering or a related subject",
        "Proven working experience in software development",
        "Working experience in iOS development",
        "Have published one or more iOS apps in the app store",
        "A deep familiarity with Objective-C and Cocoa Touch",
        "Handling social media pages at Face book, Twitter, Google+, YouTube and LinkedIn",
        "Experience working with iOS frameworks such as Core Data, Core Animation, Core Graphics and Core Text",
        "Experience with third-party libraries and APIs",
        "Solid understanding of the full mobile development life cycle",
      ],
    },
    {
      title: "ANDROID DEVELOPER",
      experience: "1-3 Years",
      responsibilities:
        "Translate designs and wireframes into high quality code. Design, build, and maintain high performance, reusable, and reliable Java code.",
      subFeatures: [
        "Strong knowledge of Android SDK, different versions of Android, and how to deal with different screen sizes",
        "Familiarity with RESTful APIs to connect Android applications to back-end services",
        "Strong knowledge of Android UI design principles, patterns, and best practices",
        "Knowledge of the open-source Android ecosystem and the libraries available for common tasks",
        "Familiarity with cloud message APIs and push notifications",
        "Familiarity with continuous integration",
      ],
    },
    {
      title: "SEO EXPERT",
      experience: "2-3 Years",
      responsibilities:
        "Execute tests, collect and analyze data, identify trends and insights in order to achieve maximum ROI in paid search campaigns. Optimize copy and landing pages for search engine marketing.",
      subFeatures: [
        "Experience with Google’s Keyword Tool",
        "Solid understanding of performance marketing, conversion, and online customer acquisition",
        "An up-to-date, working knowledge of current, past, and projected trends in the SEO industry",
        "In-depth experience with website analytics tools (e.g, Google Analytics, NetInsight, Omniture, WebTrends)",
      ],
    },
  ];
  

  return (
    <div>
      <Navbar />
      <div className="careers-container">
        <img src={careers} width="100%" alt="Careers Background" />
        <div className="overlay-text">
          <p className="join-text" style={{ overflow: "hidden" }}>
            JOIN
            <br />
            <span className="team-text">OUR TEAM</span>
          </p>
        </div>
      </div>
      <div
        className="container"
        style={{
          padding: "5%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "5%",
        }}
      >
        <div className="main-career">
          <p className="career">Careers</p>
          <p className="career-1">
            Build Your
            <br /> Career with Us
          </p>
          <p className="career-2">
            Be part of a passionate team driving positive <br />
            change. We offer dynamic career paths and support
            <br /> your growth every step of the way.
          </p>
        </div>
        <div className="right-div">
          <p className="open">Open Positions</p>
          <div className="positions">
            {positions.map((position, index) => (
              <div
                className="position-item"
                key={index}
                onClick={() => handleCareerClick(index)}
              >
                <span
                  className="plus-sign"
                  style={{ fontWeight: "400", fontSize: "20px" }}
                >
                  {expandedPosition === index ? "-" : "+"}
                </span>{" "}
                {position.title}
              </div>
            ))}
          </div>
        </div>
      </div>
      {expandedPosition !== null && (
        <div ref={cvFormRef}>
          <CVForm
            setExpandedPosition={setExpandedPosition}
            title={positions[expandedPosition].title}
            experience={positions[expandedPosition].experience}
            responsibilities={positions[expandedPosition].responsibilities}
            subFeatures={positions[expandedPosition].subFeatures}
          />
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Careers;
