import {React, useEffect} from "react";
import { Background } from "../Background";
import Navbar from "../Navbar/Navbar";
import "./Aboutus.css";
import about from "../../utils/Images/full-shot-people-having-meeting 1.webp";
import { Card } from "@mui/material";
import Image1 from "../../utils/Images/Image (5).png";
import Image2 from "../../utils/Images/Image (4).png";
import Image3 from "../../utils/Images/Image (3).png";
import Image4 from "../../utils/Images/Image (2).png";
import Footer from "../Footer/Footer";

function Aboutus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="main" style={{background:"#E3E3E3"}}>
        <Background color="#E3E3E3">
          <Navbar />
          <div className="main-div" >
            <div className="flex-display-1">
              <p className="about-1">About us</p>
              <div className="sub-div">
                <p className="service-para">
                  A Global Consulting
                  <br /> Powerhouse
                </p>
                <p className="career-2">
                  In today's dynamic digital landscape, staying ahead of the
                  curve is crucial for businesses of all sizes. Raigad
                  Consulting, a leading digital transformation consultancy based
                  in Mumbai, India, empowers businesses to unlock their full
                  potential and achieve strategic objectives through the power
                  of technology.
                  <br />
                  <br /> We are a passionate and experienced team of
                  consultants, each wielding cutting-edge expertise in their
                  respective fields. However, our strength lies not just in
                  individual brilliance, but in our collaborative approach. We
                  work hand-in-hand with our clients, fostering open
                  communication and a deep understanding of their unique needs.
                  This collaborative spirit allows us to develop and implement
                  customized digital transformation strategies that deliver
                  impactful, measurable results.
                </p>
              </div>
            </div>
            <div className="image-container">
              <img src={about} alt="About" />
            </div>
          </div>
          <div className="secnd-sec" >
            <p className="why">Why Raigad</p>
            <div className="flex-card-in">
              <Card
                className="about-card"
                sx={{ borderRadius: "15px", background: "#BDBABA" }}
              >
                <div className="about-content-card">
                  <p className="about-card-title">Industry Gurus</p>
                  <p className="about-content-1">
                    Deep expertise unlocks tailored solutions for your success.
                  </p>
                </div>
              </Card>
              <Card
                className="about-card"
                sx={{ borderRadius: "15px", background: "#BDBABA" }}
              >
                <div className="about-content-card">
                  <p className="about-card-title">Data-Driven Decisions</p>
                  <p className="about-content-1">
                    Analytics fuel insights, guaranteeing measurable results.
                  </p>
                </div>
              </Card>
              <Card
                className="about-card"
                sx={{ borderRadius: "15px", background: "#BDBABA" }}
              >
                <div className="about-content-card">
                  <p className="about-card-title">Tech Expertise</p>
                  <p className="about-content-1">
                    Cutting-edge knowledge empowers you with the latest tools.
                  </p>
                </div>
              </Card>
              <Card
                className="about-card"
                sx={{ borderRadius: "15px", background: "#BDBABA" }}
              >
                <div className="about-content-card">
                  <p className="about-card-title">Exceeding Expectations</p>
                  <p className="about-content-1">
                    Unwavering service ensures your digital transformation
                    victory.
                  </p>
                </div>
              </Card>
            </div>
          </div>
          <div className="third-sec" >
            <div className="content-wrapper">
              <div className="mission">
                <p className="our">OUR</p>
                <p className="mission-1">MISSION</p>
                <p className="our">& VISION</p>
              </div>
              <p className="description" style={{color:"#363636"}}>
                Raigad Consulting isn't just another digital transformation
                firm. We're your trusted navigator, guiding businesses through
                the ever-evolving digital landscape. We unlock your full
                potential with innovative solutions and strategic guidance,
                helping you chart a course towards success.
              </p>
            </div>
          </div>
          {/* <div className="fourth-sec">
            <div className="flex-display-1" style={{ gap: "0px" }}>
              <p className="about-1">Team</p>
              <div className="sub-div">
                <p className="service-para">Our experts</p>
              </div>
              <div className="div-desc">
                <p className="description">
                  Our powerhouse team at Raigad Consulting combines expertise
                  with experience, shaping winning digital transformation
                  strategies.
                </p>
              </div>
            </div>
            <div className="image-div">
              <div className="image-sec">
                <img src={Image4} width="100%" className="image-person" />
                <div className="inner-image-sec">
                  <p className="text-person">Unai Emery</p>
                  <p className="text-person-1">Director</p>
                </div>
              </div>
              <div className="image-sec">
                <img src={Image3} width="100%" className="image-person" />
                <div className="inner-image-sec">
                  <p className="text-person">Unai Emery</p>
                  <p className="text-person-1">Director</p>
                </div>
              </div>
              <div className="image-sec">
                <img src={Image2} width="100%" className="image-person"/>
                <div className="inner-image-sec">
                  <p className="text-person">Unai Emery</p>
                  <p className="text-person-1">Director</p>
                </div>
              </div>
              <div className="image-sec">
                <img src={Image1} width="100%" className="image-person"/>
                <div className="inner-image-sec">
                  <p className="text-person">Unai Emery</p>
                  <p className="text-person-1">Director</p>
                </div>
              </div>
            </div>
          </div> */}
        </Background>
        <Footer/>
      </div>
    </>
  );
}

export default Aboutus;
