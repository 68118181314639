import { React, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import WebDev from "../../utils/Images/Web.png";
import "./Web.css";
import { Card } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

function Web() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={WebDev} alt="Web Development" width="100%"/>
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Web Development</span>
        </p>
        <p className="heading-web">Web Development</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Websites Built to
                <br /> Convert
              </p>
              <p className="card-sub-content">
                User-friendly designs and strategic conversion optimization.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                E-commerce
                <br /> Solutions
              </p>
              <p className="card-sub-content">
                Grow your online store with secure and scalable platforms.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Content Management
                <br /> Systems (CMS)
              </p>
              <p className="card-sub-content">
                Empower your team to manage website content with ease.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Responsive
                <br /> Design
              </p>
              <p className="card-sub-content">
                Deliver a seamless experience across all devices (desktop,
                tablet, mobile).
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li class="list-items">
              In today's digital age, your website is your online storefront.
              Raigad Consulting crafts stunning and user-friendly websites that
              not only showcase your brand but also convert visitors into loyal
              customers.
            </li>
            <li class="list-items">
              Our team of experienced web developers leverages the latest
              technologies to create websites that are visually appealing,
              functionally robust, and optimized for search engines.
            </li>
            <li class="list-items">
              Whether you need a simple brochure website, a complex e-commerce
              platform, or a dynamic content management system, Raigad
              Consulting has the expertise to deliver.
            </li>
            <li class="list-items">
              We work collaboratively with you to understand your specific needs
              and goals, and then design and develop a website that exceeds your
              expectations.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Web;
