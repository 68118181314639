import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import BC1 from "../../utils/Images/BC.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function BC() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nav = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    nav(home ? "/" : "/services");
  };
  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={BC1} alt="Web Development" className="web-img" width="100%" />
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Business Consulting</span>
        </p>
        <p className="heading-web">Business Consulting</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Digital Transformation
                <br /> Strategy
              </p>
              <p className="card-sub-content">
                Develop a roadmap for successful digital transformation.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Business Process <br />
                Optimization
              </p>
              <p className="card-sub-content">
                Streamline your business processes for improved efficiency.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Change <br />
                Management
              </p>
              <p className="card-sub-content">
                Manage the transition to a digital workplace effectively.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Technology Implementation <br />& Integration
              </p>
              <p className="card-sub-content">
                Implement and integrate new technologies seamlessly.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              The digital landscape is constantly evolving, and businesses need
              to adapt to stay ahead of the curve.{" "}
              <span className="bold-para"> Raigad Consulting's</span> business
              consulting services can help you navigate the complexities of
              digital transformation and develop a roadmap for success.   
            </li>
            <li className="list-items">
              Our team of experienced consultants will work with you to assess
              your current business operations, identify areas for improvement,
              and develop a customized digital transformation strategy. We can
              also help you implement new technologies, manage change within
              your organization, and ensure a smooth transition to a digital
              workplace.
            </li>
            <li className="list-items bold-para">
              Partner with Raigad Consulting to develop a winning digital
              transformation strategy and achieve your business goals in the
              digital age.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BC;
