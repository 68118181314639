import React, { createContext, useContext } from "react";

const BackgroundColorContext = createContext();

export const useBackgroundColor = () => useContext(BackgroundColorContext);

export const Background = ({ children, color }) => {
  return (
    <BackgroundColorContext.Provider value={color}>
      {children}
    </BackgroundColorContext.Provider>
  );
};
