import React from "react";
import "./About.css";
import People from "../../utils/Images/Hero Section 1 (1).png";
import { Card } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function About() {
  const nav = useNavigate()
  const handleAboutSubClick= ()=>{
     nav('/aboutus')
  }
  return (
    <>
      <div className="container1">
        <div className="sub-container">
          <div className="image-container" style={{ width: "50%" }}>
            <img src={People} alt="People" />
          </div>

          <div className="main-about">
            <div className="global-about">
              <div>
                <p className="about">About Us</p>
                <p className="global">
                  A Global Consulting
                  <br /> Powerhouse
                </p>
              </div>
              <p className="raigad">
                Raigad Consulting Services is a rising star in the consulting
                firmament, rapidly establishing itself as a trusted advisor and
                outsourcing partner for businesses worldwide. Our dynamic team,
                headquartered in India, brings a youthful energy and global
                perspective to every project we undertake.{" "}
              </p>
              <div
              className="card-about-2"
               
              >
                <Card
                  className="bg-card"
                  sx={{ background: "#363636", borderRadius: "15px" }}
                >
                  <div className="card-content-1">
                    <p className="card-title-1-1">Innovative</p>
                    <p className="raigad-1" style={{color:"white"}}>
                      Our team stays ahead of the curve, leveraging the latest
                      technologies and industry trends to deliver cutting-edge
                      solutions.
                    </p>
                  </div>
                </Card>
                <Card
                  className="bg-card"
                  sx={{ background: "#363636", borderRadius: "15px" }}
                >
                  <div className="card-content-1">
                    <p className="card-title-1-1">Collaborative</p>
                    <p className="raigad-1" style={{color:"white"}}>
                      We believe in fostering a collaborative environment,
                      working closely with our clients to ensure their goals are
                      met
                    </p>
                  </div>
                </Card>
              </div>
              <div className="button-div">
                <Button
                  sx={{
                    borderRadius:"10px",
                    padding:"10px 20px",
                    background: "#FF9515",
                    "&:hover": {
                      background: "#FF9515",
                    },
                    lineHeight:"1.5"
                  }}
                  className="button-about"
                  onClick={handleAboutSubClick}
                >
                  <p className="read-more" style={{paddingLeft:"10px",paddingRight:"10px"}}> Read More +</p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
