import { React, useEffect } from "react";
import { Card } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Sales1 from "../../utils/Images/Sales.png";
import { useNavigate, useLocation } from "react-router-dom";

function Sales() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();
  const { home } = location.state || {};

  const handleServiceClick = () => {
    navigate(home ? "/" : "/services");
  };

  return (
    <>
      <Navbar />
      <div className="first-section">
        <img src={Sales1} alt="Web Development" className="web-img" width="100%"/>
      </div>
      <div className="second-section">
        <p className="bread-crumbs">
          <span className="ourservice" onClick={handleServiceClick}>
          {home ? "Home" : "Our Services"}/
          </span>
          <span className="brd">Sales & Marketing Experts</span>
        </p>
        <p className="heading-web">Sales & Marketing Experts</p>
        <div className="flex-card">
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Data-Driven <br />
                Marketing Strategies
              </p>
              <p className="card-sub-content">
                Develop data-driven marketing strategies to reach your target
                audience.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Lead Generation & <br />
                Conversion Optimization
              </p>
              <p className="card-sub-content">
                Generate qualified leads and convert them into paying customers.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Sales Funnel
                <br />
                Optimization
              </p>
              <p className="card-sub-content">
                Optimize your sales funnel to improve conversion rates.
              </p>
            </div>
          </Card>
          <Card className="card-in" sx={{ borderRadius: "15px" }}>
            <div className="card-content-1">
              <p className="card-title-1">
                Marketing Automation &<br /> Technology Solutions
              </p>
              <p className="card-sub-content">
                Leverage marketing automation tools to streamline your marketing
                efforts.
              </p>
            </div>
          </Card>
        </div>
        <div className="li-div">
          <ul>
            <li className="list-items">
              In today's competitive marketplace, it's more important than ever
              to have a data-driven and effective sales and marketing strategy.
              Raigad Consulting's team of experienced sales and marketing
              experts can help you develop and execute a comprehensive strategy
              that drives sales and achieves your business goals.
            </li>
            <li className="list-items">
              We use data analytics to gain valuable insights into your target
              audience and buyer behavior.This allows us to create targeted
              marketing campaigns that resonate with your audience and generate
              qualified leads.
            </li>
            <li className="list-items">
              We work closely with you throughout the entire development
              process, from initial concept to final launch, to ensure that your
              app meets your specific needs and objectives.
            </li>
            <li className="list-items">
              Our sales and marketing experts can also help you optimize your
              sales funnel, implement marketing automation tools, and improve
              your overall marketing ROI (return on investment).
            </li>
            <li className="list-items">
              Partner with Raigad Consulting to take your sales and marketing
              efforts to the next level and achieve sustainable growth.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Sales;
