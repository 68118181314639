import { React, useEffect } from "react";
import Hand from "../../utils/Images/about.png";
import "./Index.css";
import { Card } from "@mui/material";
import Web from "../../utils/Icons/web (2).svg";
import { Button } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import { Typography } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Mobile from "../../utils/Icons/mobile 2.svg";
import Design from "../../utils/Icons/design 2.svg";
import SEO from "../../utils/Icons/SEO.svg";
import Content from "../../utils/Icons/Content.svg";
import Sales from "../../utils/Icons/Sales.svg";
import BC from "../../utils/Icons/BC.svg";
import AI from "../../utils/Icons/AI.svg";
import SMO from "../../utils/Icons/SMO.svg";
import RPA from "../../utils/Icons/RPA.svg";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

function Index() {
  const isXs = useMediaQuery("(max-width:600px)");
  const isSm = useMediaQuery("(min-width:601px) and (max-width:960px)");
  const isMd = useMediaQuery("(min-width:961px) and (max-width:1280px)");
  const isLg = useMediaQuery("(min-width:1281px) and (max-width:1920px)");
  const isXl = useMediaQuery("(min-width:1921px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let imgStyles = {
    width: "100%",
    maxWidth: "70px",
    height: "auto",
    display: "block",
    margin: "0 auto",
  };

  const nav = useNavigate();

  if (isXs) {
    imgStyles.maxWidth = "50px";
  } else if (isSm) {
    imgStyles.maxWidth = "60px";
  } else if (isMd) {
    imgStyles.maxWidth = "70px";
  } else if (isLg) {
    imgStyles.maxWidth = "80px";
  } else if (isXl) {
    imgStyles.maxWidth = "90px";
  }
  const handleWebClick = () => {
    nav("/services/web-development");
  };
  const handleMobileClick = () => {
    nav("/services/mobile-development");
  };
  const handleDesignClick = () => {
    nav("/services/design-and-creatives");
  };
  const handleContentClick = () => {
    nav("/services/content-creation");
  };
  const handleSMOClick = () => {
    nav("/services/social-media-optimization");
  };
  const handleSalesClick = () => {
    nav("/services/sales-and-marketing-experts");
  };
  const handleSEOClick = () => {
    nav("/services/search-engine-optimization");
  };
  const handleRPAClick = () => {
    nav("/services/robotic-process-automation");
  };
  const handleAIClick = () => {
    nav("/services/artificial-intelligence");
  };
  const handleBCClick = () => {
    nav("/services/business-consulting");
  };
  return (
    <>
      <Navbar />
      <div style={{ padding: "0px" }}>
        <img src={Hand} alt="" width="100%" />
      </div>
      <div style={{ padding: "50px" }}>
        <div className="content-container1">
          <div className="flex-display">
            <p className="service">Our Services</p>
            <p className="service-para">
              A Global Consulting
              <br /> Powerhouse
            </p>
          </div>
        </div>
        <div
          className="card-container"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            padding:"0px",
          }}
        >
          <div className="inline">
            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white" }}
            >
              <div className="card-content">
                <img src={Web} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Web Development</p>
                  <p className="card-subcontent">
                    Craft Your Digital Presence with Raigad Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleWebClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>

            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white"  }}
            >
              <div className="card-content">
                <img src={Mobile} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Mobile App Development</p>
                  <p className="card-subcontent">
                    Engage Your Audience on-the-Go: Mobile App Development by
                    Raigad Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleMobileClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>

            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white"  }}
            >
              <div className="card-content">
                <img src={Design} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Design & Creatives</p>
                  <p className="card-subcontent">
                    Elevate Your Brand Identity: Design & Creatives by Raigad
                    Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleDesignClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>
          </div>
          <div className="inline">
            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white"  }}
            >
              <div className="card-content">
                <img src={Content} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Content Creation</p>
                  <p className="card-subcontent">
                    Compelling Content, Measurable Results: Content Creation by
                    Raigad Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleContentClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>

            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white"  }}
            >
              <div className="card-content">
                <img src={SEO} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>
                    Social Media Optimization <br />
                    (SMO)
                  </p>
                  <p className="card-subcontent">
                    Build Brand Awareness & Drive Traffic:
                    <br /> Social Media Optimization by Raigad
                    <br /> Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleSMOClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>

            <Card
              className="card-style"
              sx={{ borderRadius: "20px",background: "white"  }}
            >
              <div className="card-content">
                <img src={Sales} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Sales & Marketing Experts</p>
                  <p className="card-subcontent">
                    Boost Sales & Achieve Growth: Sales &<br /> Marketing
                    Experts at Raigad Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleSalesClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>
          </div>
          <div className="inline">
            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white"  }}
            >
              <div className="card-content">
                <img src={SMO} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Search Engine Optimization (SEO)</p>
                  <p className="card-subcontent">
                    Dominate Search Results: SEO Services by Raigad Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleSEOClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>

            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white"  }}
            >
              <div className="card-content">
                <img src={RPA} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Robotic Process Automation (RPA)</p>
                  <p className="card-subcontent">
                    Streamline Operations & Boost Productivity: RPA Solutions by
                    Raigad Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleRPAClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>

            <Card
              className="card-style"
              sx={{ borderRadius: "20px", background: "white"  }}
            >
              <div className="card-content">
                <img src={AI} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Artificial Intelligence<br/> (AI)</p>
                  <p className="card-subcontent">
                    Unlock Data-Driven Insights: Artificial Intelligence
                    Solutions from Raigad Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleAIClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>
          </div>
          <div className="inline">
            <Card
              className="card-style"
              sx={{ borderRadius: "20px",background: "white"  }}
            >
              <div className="card-content">
                <img src={BC} alt="web" style={imgStyles} />
                <div className="text-container">
                  <p className="card-title" style={{fontSize:"20px"}}>Business Consulting</p>
                  <p className="card-subcontent">
                    Thrive in the Digital Age: Business Consulting by Raigad
                    Consulting
                  </p>
                </div>
                <div className="button-container">
                  <Button
                    className="button-1"
                    sx={{
                      border: "1px solid white",
                      color: "white",
                      borderRadius: "10px",
                      background:"#FF9515",
                      "&:hover": {
                      background: "#FF9515",
                    },
                    }}
                    onClick={handleBCClick}
                  >
                    <p className="button-text">View More</p>
                  </Button>
                </div>
              </div>
            </Card>
            <Card
              className="card-style placeholder-card"
              sx={{
                borderRadius: "20px",
                background: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="card-content"></div>
            </Card>
            <Card
              className="card-style placeholder-card"
              sx={{
                borderRadius: "20px",
                background: "transparent",
                boxShadow: "none",
              }}
            >
              <div className="card-content"></div>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Index;
