import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Raigad from "../../utils/Images/Raigad Logo Final V-01 1.png";
import People from "../../utils/Images/male-female-business-people-working-tablet-office 1.png";
import { Button } from "@mui/material";
import Services from "./Services";
import About from "./About";
import Navbar from "../Navbar/Navbar";
import { Background } from "../Background";
import Testimonials from "./Testimonials";
import Footer from '../Footer/Footer'
import Team from '../Home/Team'

const NavBar = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [menuOpen, setMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [home, setHome] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);

      if (window.innerWidth > 600) {
        setMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="main">
        <Background color="white">
          <Navbar />
          <div className="main-div-1">
            <div className="flex-display-1-1">
              <p className="raigad-head">Raigad Consulting</p>
              <div className="sub-div">
                <p className="service-para">
                  Empowering Businesses with <br />
                  Innovative Solutions
                </p>
                <p className="career-2-3">
                  We are a fast-growing company offering creative solutions
                  through web development, mobile development, and digital
                  marketing. Our expertise and commitment to simplicity help
                  businesses stand out in the ever-changing market.
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "flex-start",marginTop:"5%" }}>
                <Button
                  className="button"
                  sx={{
                    background: "#FF9515",
                    padding: "10px",
                    color: "white",
                    borderRadius: "10px",
                    paddingLeft: "25px",
                    paddingRight: "25px",
                    "&:hover": {
                    background: "#FF9515",
                  },
                  }}
                >
                 <p className="button-consult"> Get a Free Consultation Today</p>
                </Button>
              </div>
            </div>
            <div className="image-container">
              <img src={People} alt="People" />
            </div>
          </div>
        </Background>
      </div>
      <About/>
      <Services/>
     
      <Team/>
      <Testimonials/>
      <Footer/>
    </>
  );
};

export default NavBar;
