import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import Raigad from "../../utils/Images/Raigad Logo Final V-01 1.png";
import { useBackgroundColor } from "../Background";
import "./Navbar.css";

const drawerWidth = 240;
const navItems = ["Home", "About Us", "Services", "Careers", "Contact"];
const serviceRoutes = [
  "/services",
  "/webdevelopment",
  "/mobiledevelopment",
  "/design",
  "/content",
  "/smo",
  "/sales",
  "/seo",
  "/rpa",
  "/ai",
  "/bc",
];

const pathMap = {
  Home: "",
  "About Us": "aboutus",
  Services: "services",
  Careers: "careers",
  Contact: "contact",
};

const StyledButton = styled(Button)(({ theme }) => ({
  cursor: "pointer",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  textAlign: "left",
  color: "black",
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&:focus": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "15px",
    marginLeft: theme.spacing(1), 
    marginRight: theme.spacing(1),
  },
}));

const SelectedButton = styled(StyledButton)({
  color: "#FF9515",
  fontWeight: "700",
  textDecoration: "underline",
  textUnderlineOffset: "2px",
  "&:hover": {
    textDecoration: "underline",
    textUnderlineOffset: "2px",
  },
  "@media (max-width: 968px)": {
    fontSize: "15px", 
    marginLeft: "8px",
    marginRight: "8px",
  },
});

function Navbar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("Home");
  const location = useLocation();
  const navigate = useNavigate();
  const backgroundColor = useBackgroundColor();
  const nav = useNavigate()
  const handleLogoClick = ()=>{
    nav('/')
  }

  useEffect(() => {
    const path = location.pathname;
    if (serviceRoutes.includes(path)) {
      setSelectedItem("Services");
    } else {
      setSelectedItem(
        Object.keys(pathMap).find(
          (key) => pathMap[key] === location.pathname.substring(1)
        ) || "Services"
      );
    }
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavItemClick = (item) => {
    setSelectedItem(item);
    navigate(`/${pathMap[item] || ""}`);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ my: 2 }}>
        <img src={Raigad} alt="Logo" style={{ width: "150px" }} onClick={handleLogoClick}/>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item}
            disablePadding
            sx={{
              position: "relative",
              "&::before": {
                content: "none",
              },
            }}
          >
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => handleNavItemClick(item)}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          boxShadow: "none",
          background: backgroundColor || "white",
          position: "relative",
          paddingTop: "20px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              // "@media (max-width: 900px)": {
              //   display: "none",
              // },
            }}
          >
            <img src={Raigad} alt="Large Logo" className="image-styles" onClick={handleLogoClick}/>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {navItems.map((item) => {
              const isSelected = selectedItem === item;
              return isSelected ? (
                <SelectedButton
                  key={item}
                  onClick={() => handleNavItemClick(item)}
                >
                  {item}
                </SelectedButton>
              ) : (
                <StyledButton
                  key={item}
                  onClick={() => handleNavItemClick(item)}
                >
                  {item}
                </StyledButton>
              );
            })}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
